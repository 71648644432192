@use '../base/variables';

@layer components {
  .form-container {
    @each $key, $value in variables.$form-container-width {
      &-#{$key} {
        @apply max-w-full;

        width: $value;
      }
    }
  }
}
