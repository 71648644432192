@use '../base/variables';

.text-hint {
  @apply text-monochrome-700;

  font-size: variables.$font-size-smaller;
}

.p-inherit p {
  color: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}
