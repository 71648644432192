mf-chart {
  .chart-container {
    &.no-export {
      .highcharts-subtitle {
        &::after {
          width: calc(100% + 30px);
        }
      }
    }
  }

  .highcharts-container {
    .highcharts-contextbutton {
      cursor: pointer;
    }

    .highcharts-title,
    .highcharts-subtitle {
      display: block !important;
      width: calc(100% - 50px) !important;
    }
  }
}
