@use '../base/variables';
@use '../utils';

.mat-form-field {
  &.mat-form-field-warning {
    &:not(.mat-form-field-invalid) {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          @apply border-monochrome-800;
        }

        .mat-form-field-outline-gap {
          border-top-color: transparent !important;
        }
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          mat-label {
            @apply text-monochrome-800;
          }
        }
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        @apply border-monochrome-600 #{!important};
      }

      .mat-form-field-outline-gap {
        border-top-color: transparent !important;
      }
    }

    .mat-form-field-infix {
      @apply text-monochrome-600 #{!important};
    }

    .mat-form-field-label-wrapper {
      .mat-form-field-label {
        mat-label {
          @apply text-monochrome-600 #{!important};
        }
      }
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-outline {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        @apply border-error-500 #{!important};
      }

      .mat-form-field-outline-gap {
        border-top-color: transparent !important;
      }
    }

    .mat-form-field-infix {
      @apply text-error-500 #{!important};
    }

    .mat-form-field-label-wrapper {
      .mat-form-field-label {
        mat-label {
          @apply text-error-500 #{!important};
        }
      }
    }
  }

  &.mat-form-field-appearance-outline {
    width: 100%;

    &.mat-paginator-page-size-select {
      margin: 0;
      width: auto;

      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border-color: transparent !important;
        }
      }

      .mat-form-field-infix {
        width: auto;

        .mat-select {
          width: auto;

          .mat-select-value {
            max-width: none;
            width: auto;
          }

          .mat-select-arrow-wrapper {
            transform: translateY(0%);
          }
        }
      }
    }

    &:not(.mat-focused) {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          @apply border-monochrome-800;
        }

        .mat-form-field-outline-gap {
          border-top-color: transparent;
        }
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          mat-label {
            @apply text-monochrome-900;
          }
        }
      }
    }

    &.no-label {
      .mat-form-field-flex {
        margin-top: 0 !important;
      }

      .mat-form-field-outline {
        top: 0 !important;
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        top: 0.3em !important;

        .mat-datepicker-toggle {
          button {
            .mat-datepicker-toggle-default-icon {
              height: 1.4rem;
              width: 1.4rem;
            }
          }
        }
      }

      .mat-form-field-infix {
        border-bottom: 1px solid transparent !important;
        border-top: 1px solid transparent !important;
      }

      .mat-select-arrow-wrapper {
        transform: translateY(0) !important;
      }

      .mat-form-field-label-wrapper,
      .mat-form-field-subscript-wrapper {
        display: none;
      }
    }

    .mat-form-field-outline {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-radius: variables.$border-radius;
        border-width: 1px;
      }
    }

    .mat-form-field-wrapper {
      margin-top: 0;
      margin-bottom: 8px;
      padding-bottom: 32px;

      .mat-form-field-subscript-wrapper {
        position: inherit;
        margin: 4px 0;
        display: inherit;
      }
    }

    &.no-bottom-offset {
      .mat-form-field-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;

        .mat-form-field-subscript-wrapper {
          margin: 0;
        }
      }

      mat-error,
      mf-form-util-length-handler {
        margin-top: 4px;
      }
    }

    .mat-form-field-infix {
      padding: 8px 0 !important;
    }

    .text-prefix-container {
      display: flex;
      flex-direction: row-reverse;
    }

    .mat-form-field-label-wrapper {
      top: -20px;
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float {
        .mat-form-field-label {
          transform: translateY(-20px) scale(0.75);
          width: 133.3333%;
        }
      }
    }

    &.simple-suffix {
      &.mat-form-field-disabled {
        .mat-form-field-suffix {
          @apply text-monochrome-600;
        }
      }

      .mat-form-field-suffix {
        top: 0 !important;
      }
    }

    .mat-form-field-suffix {
      transform: scale(0.9);

      .mat-datepicker-toggle {
        button {
          padding: 0;
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      .mat-icon {
        @apply text-monochrome-700;

        &#input-eye-icon {
          margin-top: 8px;
        }

        &.cursor-pointer {
          @apply text-monochrome-800;
        }

        mat-icon {
          fill: currentColor;
        }
      }
    }

    &.is-table-search,
    &.is-table-filter-search,
    &.mat-paginator-page-size-select {
      &.mat-focused {
        .mat-form-field-prefix {
          .mat-icon {
            &[matprefix] {
              @apply text-primary-500;
            }
          }
        }
      }

      .mat-form-field-wrapper {
        @apply pb-0;
      }

      .mat-form-field-infix {
        border-bottom: 4px solid transparent;
        border-top-width: 10px;
      }

      .mat-form-field-prefix {
        top: 6px;

        .mat-icon {
          &[matprefix] {
            @apply text-monochrome-700;

            transform: scale(0.8);
          }
        }
      }
    }

    &.is-table-search {
      .mat-form-field-wrapper {
        @apply mb-0;
      }

      .mat-form-field-subscript-wrapper {
        @apply hidden;
      }
    }
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        @apply bg-white;
      }
    }
  }

  input.mat-input-element {
    line-height: 1.25;
    margin-top: 0;
  }
}

.mat-hint,
.mat-error {
  width: 100%;
}

mf-form-util-hint-handler,
.mat-hint,
.mat-error {
  .mat-icon {
    @include utils.size(16px, 16px);

    min-height: 16px;
    min-width: 16px;
  }
}

.mat-form-field-hint-spacer {
  flex: 1 0 0 !important;
}

.has-success {
  @apply text-green-500;
}

input {
  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }

    &.hide-number-spinner {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        opacity: 0;
      }
    }
  }
}

.checkboxes-list {
  padding: 0 !important;

  mat-list-option {
    background: none !important;
    font-size: variables.$font-size-base !important;
    height: auto !important;
    margin: 1rem 0 !important;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    .mat-list-item-content {
      padding: 0 !important;
    }

    .mat-list-text {
      padding-left: 8px !important;
    }
  }
}

.number-input {
  .mat-form-field-flex {
    width: 85px;
  }
}

mf-form-topic-selector-multiple {
  .mat-form-field {
    &.mat-form-field-appearance-outline {
      width: 151%;
    }
  }
}
