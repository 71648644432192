@use '../base/variables';

%single-and-all-column-width {
  .expand-cell {
    width: 0.1%;
  }

  .name-cell {
    width: 22% !important;
  }

  .type-cell {
    text-align: left;
    width: 18%;
  }

  .state-cell {
    text-align: left;
    width: 14%;
  }

  .user-responsible-cell {
    text-align: left;
    width: 16%;
  }

  .period-cell {
    text-align: left;
    width: 12%;
  }

  .medium-cell {
    width: 17%;
  }

  .estimate-value-cell {
    text-align: right;
    width: 14% !important;
  }

  .action-cell {
    width: 0;
  }
}

%permanent-type-column-width {
  .expand-cell {
    width: 0.1%;
  }

  .name-cell {
    width: 21% !important;
  }

  .type-cell {
    text-align: left;
    width: 18%;
  }

  .state-cell {
    text-align: left;
    width: 12%;
  }

  .user-responsible-cell {
    text-align: left;
    width: 17%;
  }

  .medium-cell {
    width: 15%;
  }

  .action-cell {
    width: 0;
  }
}

.table-wrapper {
  .mat-table {
    &.campaign-table {
      &.single-or-all {
        @extend %single-and-all-column-width;
      }

      &.permanent {
        @extend %permanent-type-column-width;
      }

      th {
        text-align: center;
      }

      .mat-row {
        &.parent-is-expanded {
          td {
            border-bottom: 0;
          }

          .focus-topic-consultation {
            height: 80px !important;
          }
        }

        .mat-cell {
          min-height: 72px;

          .period-info-text {
            font-size: 12px;
          }

          .estimate-permanent-not-started {
            cursor: default;
          }

          &.mat-column-name {
            font-size: variables.$font-size-small;
            font-weight: variables.$font-weight-regular;
            line-height: 16px;
            position: relative;

            .focus-topic-consultation {
              @apply bg-monochrome-700;

              height: 40px;
              left: 0;
              position: absolute;
              width: 2px;
            }
          }
        }
      }
    }
  }
}

#detail-row {
  &.is-collapsed {
    display: none;
    height: 0;
    overflow: clip;

    .mat-cell {
      height: auto;
      padding: 0;
    }
  }

  &.is-expanded {
    .mat-cell {
      padding: 0;
    }

    .table-wrapper,
    .table-container,
    .mat-table {
      overflow: visible !important;

      &.single-or-all {
        @extend %single-and-all-column-width;
      }

      &.permanent {
        @extend %permanent-type-column-width;
      }

      .mat-cell {
        padding: 12px 16px;

        &.no-padding {
          padding: 0;
        }

        &.expand-cell {
          button {
            cursor: default;
          }
        }

        &.name-cell {
          padding-left: 16px;
        }
      }

      .table-column-right {
        padding-right: 32px;
      }

      .permanent-child-row {
        font-weight: variables.$font-weight-regular;

        td {
          border-bottom: 0;
        }

        .focus-topic-consultation {
          height: 80px;

          .child-campaign-dot {
            background-color: transparent !important;
            left: -0.67rem;
            position: absolute;
            top: 0.39rem;

            span {
              @apply text-monochrome-100;

              font-size: 12px;
              font-weight: variables.$font-weight-medium;
              left: 8px;
              position: absolute;
              top: 5px;
            }
          }
        }

        &:last-of-type {
          .focus-topic-consultation {
            height: 20px;
          }
        }
      }
    }
  }
}
