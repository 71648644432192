@use 'sass:math';

$panel-padding: 12px;
$tooltip-padding: 8px;
$arrow-size-percent: 0.66;
$arrow-size: $panel-padding * $arrow-size-percent;
$tooltip-background: rgb(97 97 97 / 90%);

.mat-tooltip-panel {
  &.mat-tooltip-panel-below {
    .mat-tooltip::before {
      border-bottom-color: $tooltip-background;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }

  &.mat-tooltip-panel-above {
    .mat-tooltip::before {
      border-top-color: $tooltip-background;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }

  &.mat-tooltip-panel-left {
    .mat-tooltip::before {
      border-left-color: $tooltip-background;
      left: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &.mat-tooltip-panel-right {
    .mat-tooltip::before {
      border-left-color: $tooltip-background;
      right: 0;
      top: 50%;
      transform: translate(100%, -50%);
    }
  }

  .mat-tooltip {
    @apply overflow-visible rounded text-xs;

    margin: $panel-padding;
    padding: $tooltip-padding;
    background-color: $tooltip-background;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border: $arrow-size solid transparent;
      position: absolute;
    }
  }
}
