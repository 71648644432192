@use '../utils';
@use '../base/variables';

.content-hint {
  @include utils.size(100%, auto);

  @apply border-b border-t border-monochrome-600 text-monochrome-800;

  font-size: variables.$font-size-base;
  min-height: 56px;
  padding: 16.5px;
}
