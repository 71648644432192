.radio-list {
  @apply flex flex-col;

  > mat-radio-button {
    @apply w-fit;

    margin: 0.3em 0;

    &.has-info {
      @apply m-0;
    }
  }
}

.radio-card-list {
  @apply flex gap-4;

  > mat-radio-button {
    @apply m-0 min-w-0;

    &.mat-radio-checked {
      mf-card {
        @apply border-2 border-primary-500;
      }
    }

    .mat-radio-label {
      @apply text-wrap;

      .mat-radio-container {
        @apply hidden;
      }

      .mat-radio-label-content {
        @apply max-w-full p-0;
      }
    }

    &.has-info {
      @apply m-0;
    }
  }
}

.radio-grow {
  .mat-radio-label {
    @apply w-full grow;

    .mat-radio-label-content {
      @apply w-full;
    }
  }
}
