@use '../base/variables';

.mat-snack-bar-container {
  &.notification {
    @apply flex items-center bg-white text-monochrome-800;

    font-size: variables.$font-size-smaller;
    min-height: 72px;
    padding: 0;

    mf-notification-content {
      cursor: pointer;
      display: flex;
      height: 100%;

      .notification-content {
        padding: 1rem 1.5rem 1rem 1rem;

        mat-icon {
          margin-right: 12px !important;
          transform: scale(1);
        }

        &.success {
          mat-icon {
            @apply text-green-500;
          }
        }

        &.info {
          mat-icon {
            @apply text-primary-500;
          }
        }

        &.error {
          @apply text-error-500;
        }

        .title {
          font-weight: variables.$font-weight-bold;
        }

        .title,
        .message {
          font-size: variables.$font-size-smaller;
          line-height: 16px;
        }
      }
    }
  }
}
