// TODO: Refactor both styles into _buttons.scss
.is-spinner-inside-button {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mat-progress-spinner {
  &.is-white {
    circle {
      stroke: #fff;
    }
  }
}
