form {
  opacity: 1;
  transition: opacity 0.2s ease;

  &.is-disabled {
    opacity: 0.25;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }
}
