@use '../base/variables';

.mf-sidenav {
  @apply h-full w-full overflow-hidden bg-monochrome-100 text-sm text-monochrome-900;

  .mf-logo-wrapper {
    @apply flex-shrink-0;

    height: variables.$header-height;
  }

  .group {
    @apply flex flex-col;
  }

  .header {
    @apply cursor-default select-none text-xs uppercase text-monochrome-800;
  }

  .header,
  .item {
    @apply px-6 py-2;
  }

  .item {
    @apply cursor-pointer transition-colors hover:bg-monochrome-200;

    &.with-icon {
      @apply flex items-center space-x-2;
    }

    &.active {
      @apply font-bold text-primary-500;
    }
  }
}

.mat-menu-panel {
  &.mf-sidenav-user-menu {
    width: variables.$sidenav-width;
  }
}
