@font-face {
  font-family: 'Intelo';
  src:
    local('Intelo Bold'),
    local('Intelo-Bold'),
    url('/assets/fonts/intelo/Intelo-Bold.woff2') format('woff2'),
    url('/assets/fonts/intelo/Intelo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Intelo';
  src:
    local('Intelo Bold Italic'),
    local('Intelo-BoldItalic'),
    url('/assets/fonts/intelo/Intelo-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/intelo/Intelo-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Intelo';
  src:
    local('Intelo Italic'),
    local('Intelo-Italic'),
    url('/assets/fonts/intelo/Intelo-Italic.woff2') format('woff2'),
    url('/assets/fonts/intelo/Intelo-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Intelo';
  src:
    local('Intelo'),
    local('Intelo-Regular'),
    url('/assets/fonts/intelo/Intelo-Regular.woff2') format('woff2'),
    url('/assets/fonts/intelo/Intelo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
