@use '../base/variables';
@use '../utils';

mf-primary-button,
mf-secondary-button,
mf-tertiary-button,
mf-table-button,
.mf-custom-button {
  display: inline-block;
  appearance: none !important;

  .mat-button-base {
    border-radius: variables.$border-radius;
    line-height: 36px;

    &.auto-stretch {
      width: 100%;
    }

    & > .mat-button-wrapper {
      font-size: variables.$font-size-small;
      font-weight: variables.$font-weight-regular;
      letter-spacing: 0.25px;
      text-transform: uppercase;

      .button-label {
        transform: translateY(1px);
      }
    }

    &.small {
      padding: 0 0.5em 0 0.2em;
    }
  }
}

mf-secondary-button {
  &.add-rule,
  &.add-block {
    .mat-button-wrapper {
      display: contents;
    }
  }

  .mat-button-base {
    padding: 0 13px;
  }
}

mf-primary-button,
mf-tertiary-button,
mf-table-button {
  .mat-button-base {
    & > .mat-button-wrapper {
      display: contents;
      font-weight: variables.$font-weight-medium;
    }
  }
}

mf-table-button {
  button {
    &.mat-button {
      padding-left: 0;
      padding-right: 0;

      div {
        &.mat-button-focus-overlay {
          opacity: 0 !important;
        }
      }
    }
  }
}

.mf-custom-button {
  .btn-primary,
  .btn-tertiary {
    &.mat-button-base {
      & > .mat-button-wrapper {
        font-weight: variables.$font-weight-medium;
      }
    }
  }
}

.mat-stroked-button {
  &.btn-secondary {
    @apply border-monochrome-600;
  }

  &.btn-tertiary {
    @apply border-transparent;
  }
}

.mat-flat-button {
  &.is-filter-reset-button {
    @apply text-monochrome-800;

    font-size: variables.$font-size-small;
    font-weight: variables.$font-weight-regular;
    transform: translateY(2px);

    .mat-icon {
      @include utils.size(variables.$font-size-small, auto);

      @apply text-monochrome-800;

      transform: translateY(2px);
    }
  }
}

.mat-icon-button {
  &.mat-button-disabled {
    @apply cursor-default #{!important};
  }
}
