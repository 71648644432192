@use '../utils';
@use '../base/variables';

.container {
  width: 100%;

  &.info {
    @include utils.size(100%, auto);

    @apply text-monochrome-800;

    border-bottom: 1px solid rgb(0 0 0 / 10%);
    border-top: 1px solid rgb(0 0 0 / 10%);
    font-size: variables.$font-size-small;
    min-height: 56px;
    padding: 24px 8px;
  }
}
