@use '../base/variables';

.import-dropzone {
  &[ngx-dropzone] {
    @apply border border-dashed border-monochrome-700;

    border-radius: variables.$card-border-radius;
    height: variables.$dropzone-height;

    &.ngx-dz-hovered {
      @apply border border-dashed border-primary-500 bg-primary-50;
    }
  }
}
