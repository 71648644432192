/*
For some reason the freshworks help-widget is setting a min-width which causes the clickable area to be wider than visually expected.
This results in miss-clicks and non-reachable areas especially on smaller screens.

Therefore we just override the min-width in order to align the visual size with the actual clickable size.
*/

#freshworks-container {
  @apply fixed z-30 #{!important};

  #launcher-frame {
    min-width: unset !important;
  }
}
