@use '../base/variables';

mat-chip-list {
  &.single-number-chip-list {
    mat-chip {
      &.mat-standard-chip {
        @apply bg-monochrome-800 text-white;

        font-size: variables.$font-size-base;
        font-weight: variables.$font-weight-regular;

        &.mat-chip-selected {
          @apply bg-primary-500;
        }
      }
    }
  }

  &.focus-topic-chips {
    mat-chip {
      &.mat-standard-chip {
        font-size: variables.$font-size-smaller;
        font-weight: variables.$font-weight-regular;
        line-height: 14px;
        min-height: 24px;
        padding: 7px 10px;

        &::after {
          display: none !important;
        }

        @each $topic in variables.$topic-list {
          &.#{ $topic } {
            @apply bg-topic-#{$topic}-100 text-topic-#{$topic}-700;
          }
        }
      }
    }
  }
}
