@use '../base/variables';

.mat-error {
  &.error-custom {
    font-size: variables.$font-size-small;
  }

  .custom-hint-error-container {
    margin-top: 4px;
    min-height: calc(32px - 4px);
  }
}
