.mf-dialog {
  .mat-dialog-container {
    overflow: visible;
  }
}

.mf-sidebar {
  .mat-dialog-title {
    @apply text-3xl;
  }

  .mat-dialog-container {
    > * {
      @apply flex h-full flex-col;

      .mat-dialog-content {
        @apply max-h-[unset] min-h-0 flex-grow;
      }
    }
  }

  .mat-dialog-actions {
    &.styled {
      @apply justify-start;
    }
  }
}

.mat-dialog-container {
  border-radius: 0 !important;
  position: relative;

  .mat-dialog-title {
    @extend .mat-headline;
  }

  .mat-dialog-content {
    &.dialog-content-visible {
      overflow: visible;
    }
  }
}

.dialog-close.mat-icon-button {
  position: absolute;
  right: 8px;
  top: 8px;
}

.mf-dialog-transfer-campaign {
  .mat-dialog-container {
    overflow: visible;
  }
}

.mat-dialog-actions {
  &.styled {
    @apply flex justify-end gap-2;
  }
}

.mf-plain-modal,
.mf-fullscreen-modal {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.mf-dialog-image {
  .mat-dialog-container {
    padding: 0;

    .mat-dialog-content {
      align-items: center;
      display: flex;
      place-content: center;
      margin: 0;
      padding: 0;
    }

    .mat-dialog-actions {
      margin: 0;
      min-height: 0;
      padding: 8px;
    }
  }
}
