@use '../utils';
@use '../base/variables';

mf-layout-footer {
  > .mat-toolbar {
    @include utils.size(100%, variables.$footer-height);

    border-left: 2px solid #fff;

    @apply bg-monochrome-100;

    padding-left: 50px;
    padding-right: 50px;

    .footer-links {
      a {
        @apply text-monochrome-800;

        text-decoration: none;
        font-size: variables.$font-size-base;
        font-weight: variables.$font-weight-regular;

        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }

    .footer-logo {
      display: block;
      line-height: 0;

      img {
        @include utils.size(auto, 24px);
      }
    }
  }
}
