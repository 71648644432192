@keyframes blinking {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.animation-blink {
  animation: blinking 2s ease infinite;
}
