.medium-detail {
  .mat-subheading-2,
  .mat-subheading-1 {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .mat-subheading-2 {
    @apply text-monochrome-800;

    margin-top: 24px;
  }
}

mf-campaign-upsert-medium-email-upsert {
  mf-form-date-time-picker {
    .ng-dropdown-panel {
      min-width: auto !important;
    }
  }
}

mf-selection-lara-builder-editor-filter-leaf {
  .ng-dropdown-panel {
    min-width: 460px !important;
  }
}
