@use '../base/variables';

.mat-menu-panel {
  border-radius: 0 !important;
  min-height: variables.$dropdown-item-height !important;

  .mat-menu-content:not(:empty) {
    padding-bottom: 0;
    padding-top: 0;

    .mat-menu-item {
      font-size: variables.$font-size-base;
      height: variables.$dropdown-item-height;
      line-height: variables.$dropdown-item-height;
    }
  }
}
