@charset "utf-8";

@use './stylesheets/base/variables';

@import 'stylesheets/framework';
@import 'stylesheets/utils';
@import 'stylesheets/partials';

html,
body {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
}
