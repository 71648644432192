@use '../base/variables';

.mat-menu-panel {
  &.filter-multiple-menu {
    max-height: 50vh;
    max-width: 30vh;

    .mat-menu-content {
      padding: 16px 0;

      .search-container {
        padding: 0 16px 16px;
      }

      .mat-optgroup {
        .mat-optgroup-label {
          height: auto;
          line-height: 1.4em;
          padding-bottom: 0.2rem;
          padding-top: 1rem;
          white-space: normal;
        }

        // First item after search
        &:nth-child(2) {
          .mat-optgroup-label {
            padding-top: 0;
          }
        }

        @each $topic in variables.$topic-list {
          &.#{ $topic } {
            > .mat-optgroup-label {
              @apply text-topic-#{ $topic }-500;
            }
          }
        }
      }

      .mat-option {
        &.filter-multiple-option {
          font-size: variables.$font-size-small;
          height: auto;
          letter-spacing: 0.13px;
          line-height: 1.5em;
          padding: 4px 0;
          white-space: normal;

          @each $topic in variables.$topic-list {
            &.#{ $topic } {
              .mat-checkbox-label {
                @apply text-topic-#{ $topic }-500;
              }
            }
          }

          &.mat-selected {
            &:not(:hover) {
              &:not(:focus) {
                &:not(.mat-option-multiple) {
                  &:not(.mat-option-disabled) {
                    background: inherit;
                  }
                }
              }
            }
          }

          .mat-checkbox {
            align-content: center;
            align-items: center;
            display: flex;
            pointer-events: none;

            .mat-checkbox-layout {
              padding-right: 16px;
              white-space: normal;

              .mat-checkbox-inner-container {
                margin-top: 4px;
              }
            }
          }

          .sublabel {
            @apply text-monochrome-800;
          }
        }
      }
    }
  }

  .multiline-menu-item {
    line-height: 1.4rem !important;
    white-space: normal !important;
  }
}

.mat-menu-trigger {
  &.filter-multiple-menu-trigger,
  &.filter-date-menu-trigger {
    border-radius: 0;
    font-size: variables.$font-size-small;
    font-weight: variables.$font-weight-regular;
    padding-left: 8px;
    padding-right: 0;

    &.opened {
      @apply bg-primary-50 text-monochrome-800;
    }

    &.active {
      @apply text-primary-500;

      font-weight: variables.$font-weight-medium;

      .mat-icon {
        @apply text-primary-500;
      }
    }

    .mat-icon {
      @apply text-monochrome-800;

      transform: scale(0.8);
    }
  }
}
