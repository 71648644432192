@use '../base/variables';
@use 'sass:map';

.table-wrapper {
  width: 100%;

  .is-hidden {
    height: 0;
    max-height: 0;
    visibility: hidden;
  }

  .is-visible {
    visibility: visible;
  }

  .table-container {
    overflow: auto;
    width: 100%;

    &.full-height {
      max-height: none;
    }

    .mat-table {
      margin: 0 auto;

      // Hide rows when collapsed
      overflow: hidden;
      user-select: none;
      width: 100%;

      thead {
        .mat-header-row {
          .mat-header-cell {
            @apply border-b border-t border-monochrome-600 text-monochrome-900;

            font-size: variables.$font-size-small;
            font-weight: variables.$font-weight-medium;

            &.table-column-left {
              .mat-sort-header-container {
                justify-content: flex-start;

                .mat-sort-header-content {
                  text-align: left;
                }
              }
            }

            &.table-column-center {
              .mat-sort-header-container {
                justify-content: center;

                .mat-sort-header-content {
                  text-align: center;
                }
              }
            }

            &.table-column-right {
              .mat-sort-header-container {
                justify-content: flex-end;

                .mat-sort-header-content {
                  text-align: right;
                }
              }
            }
          }
        }
      }

      tbody {
        .mat-row {
          &.disabled {
            .mat-cell {
              &,
              .name,
              .subline {
                @apply text-monochrome-500;
              }
            }
          }

          &.double-row-top {
            height: auto !important;

            .mat-cell {
              border-bottom: 0;
              height: auto !important;
              padding-bottom: 0;
            }
          }

          &.double-row-bottom {
            height: auto !important;

            .mat-cell {
              height: auto !important;
              padding-top: 4px;
            }
          }

          .mat-cell {
            @apply border-b border-monochrome-500 text-monochrome-800;

            font-size: variables.$font-size-small;
            height: variables.$table-row-height;
            max-height: 100%;

            &:first-of-type {
              font-weight: variables.$font-weight-medium;

              .subline {
                font-weight: variables.$font-weight-regular;
              }
            }

            &.table-column-right {
              padding-right: 32px;
              text-align: right;
              width: 180px;
            }

            &.table-column-center {
              text-align: center;
            }

            &.smaller {
              width: 100px;
            }

            &.wider {
              width: 200px !important;
            }

            .no-wrap {
              white-space: nowrap;
            }

            .truncated {
              @apply inline-block truncate;

              &,
              &.max-150 {
                max-width: 150px;
              }
            }

            &.warning {
              @apply text-warn-500;
            }
          }

          &:last-of-type {
            .mat-cell {
              @apply border-b border-monochrome-500;
            }
          }
        }
      }

      thead,
      tbody {
        .mat-header-row,
        .mat-row {
          .mat-header-cell,
          .mat-cell {
            padding: 12px 16px;
            background: unset;
          }
        }

        // action
        .mat-column-action {
          text-align: end;
          width: 0;
        }
      }
    }
  }

  // disabled row styles
  .mat-table {
    tbody {
      .mat-row {
        .mat-cell {
          &.is-disabled {
            @apply text-monochrome-500;
          }
        }
      }
    }
  }

  mat-paginator {
    .mat-form-field-infix {
      border-top-width: 18px !important;
    }
  }
}

// sortable
.mat-table {
  &[matsort] {
    thead {
      .mat-header-row {
        .mat-header-cell {
          .mat-sort-header-button {
            text-align: left;
          }
        }
      }
    }
  }

  .name {
    @apply truncate text-monochrome-900;

    font-size: variables.$font-size-small;
    font-weight: variables.$font-weight-medium;
    line-height: 16px;
    max-width: 200px;
  }

  .subline {
    @apply text-monochrome-800;

    font-size: variables.$font-size-smaller;
    font-weight: variables.$font-weight-regular;
    line-height: 18px;
    margin-top: 4px;
  }

  .truncated {
    @apply truncate;

    max-width: 125px;
  }

  .table-icon-container {
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    min-width: 0;
    padding: 0;
    text-align: center;
    width: 40px;

    .mat-icon {
      line-height: 24px;
      vertical-align: middle;
    }
  }

  // table for bank-list
  &.bank-table {
    @media (max-width: map.get(variables.$breakpoints, 'md')) {
      thead,
      tbody {
        .mat-header-cell,
        .mat-cell {
          button,
          span {
            @apply block truncate;
          }
        }

        // name
        .mat-column-name {
          button,
          &.mat-cell {
            span {
              max-width: 160px;
              width: 100%;
            }
          }
        }

        // city
        .mat-column-city {
          max-width: 120px;
          width: 70%;
        }

        // zip
        .mat-column-zip {
          max-width: 80px;
          width: 50%;
        }

        // bic
        .mat-column-bic {
          max-width: 80px;
          width: 50%;
        }
      }
    }

    thead,
    tbody {
      // createdAt
      /* stylelint-disable-next-line selector-class-pattern */
      .mat-column-createdAt {
        max-width: 140px;
        width: 140px;

        @media (max-width: map.get(variables.$breakpoints, 'lg')) {
          display: none;
        }
      }

      .download-invoice-cell {
        text-align: center;
      }
    }
  }

  // table for emni-admin user-list
  &.user-table {
    tbody {
      tr {
        height: 72px;

        .mat-cell {
          height: 100%;
        }

        &.banned {
          .mat-cell {
            &,
            > * {
              @apply text-monochrome-500;
            }
          }
        }
      }
    }

    // name
    @media (max-width: map.get(variables.$breakpoints, 'md')) {
      tbody {
        .mat-column-name {
          &.mat-cell {
            div {
              max-width: 160px;
              width: 100%;

              &:last-of-type {
                @apply truncate;

                font-weight: variables.$font-weight-regular;
              }
            }
          }
        }
      }
    }

    thead,
    tbody {
      // roles
      .mat-column-roles {
        &.mat-cell {
          div {
            max-width: 160px;
            min-width: 160px;
            width: 100%;
          }
        }
      }

      // createdAt
      /* stylelint-disable-next-line selector-class-pattern */
      .mat-column-createdAt {
        max-width: 140px;
        width: 140px;

        @media (max-width: map.get(variables.$breakpoints, 'lg')) {
          display: none;
        }
      }
    }
  }
}

// table for data import-list
.import-table {
  @media (max-width: map.get(variables.$breakpoints, 'lg')) {
    thead,
    tbody {
      .mat-column-name {
        button,
        &.mat-cell {
          span {
            @apply block w-full truncate;

            max-width: 160px;
          }
        }
      }
    }
  }

  td {
    &.name {
      width: 20%;
    }

    &.info {
      width: 27.5%;
    }

    &.duration {
      width: 10%;
    }

    &.status {
      width: 27.5%;
    }

    &.download {
      width: 15%;
    }
  }

  .state-success {
    @apply text-green-500;
  }

  .state-info {
    .mat-icon {
      @apply text-primary-500;
    }
  }

  .state-waiting {
    .mat-icon {
      @apply text-monochrome-700;
    }
  }

  .state-error {
    @apply text-error-500;

    .mat-icon {
      @apply text-error-500;
    }
  }
}
