@use '../base/variables';

quill-editor {
  .ql-snow {
    &.ql-container,
    &.ql-toolbar {
      @apply border-monochrome-800;
    }

    .ql-tooltip {
      z-index: 1;

      &::before {
        content: 'Aufrufen:';
      }

      &[data-mode='link']::before {
        content: 'Link eingeben:';
      }

      a {
        &.ql-action,
        &.ql-remove {
          @apply text-primary-500 hover:text-primary-700;

          font-weight: 500;
          text-transform: uppercase;
        }

        &.ql-action {
          &::after {
            content: 'Bearbeiten';
          }
        }

        &.ql-remove {
          &::before {
            content: 'Entfernen';
          }
        }
      }

      &.ql-editing {
        a {
          &.ql-action {
            @apply bg-primary-500 text-white hover:bg-primary-700;

            border-radius: 1px;
            font-weight: 500;
            margin: -12px;
            margin-left: 8px;
            padding: 10px 16px;
            text-transform: uppercase;

            &::after {
              content: 'Speichern';
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &.error {
    .ql-container,
    .ql-toolbar {
      @apply border-error-500;
    }
  }

  &.warning {
    .ql-container,
    .ql-toolbar {
      @apply border-warn-500;
    }
  }

  .ql-container {
    font-family: inherit;
    font-size: variables.$font-size-base;

    p {
      @apply text-black;

      font-size: inherit;
      line-height: inherit;
    }

    .ql-editor {
      p,
      ul,
      ol {
        &:not(:last-child) {
          margin-bottom: 0.8em;
        }
      }
    }
  }

  .ql-snow.ql-toolbar {
    .ql-formats {
      margin-right: 0 !important;
      margin-left: 4px;
      padding-right: 4px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:not(:last-child) {
        @apply border-r border-monochrome-300;
      }
    }

    .mf-ql-placeholder {
      @apply inline-flex w-auto items-center space-x-1 text-sm;

      mat-icon {
        @apply w-5;
      }
    }

    button,
    .ql-picker-label {
      &:hover,
      &.ql-active {
        @apply text-primary-500;

        .ql-stroke {
          @apply text-primary-500;

          stroke: currentColor;
        }
      }
    }

    .ql-picker {
      .ql-picker-options {
        max-height: 200px;
        overflow: auto;
        z-index: 40;
      }

      .ql-picker-item {
        &:hover,
        &.ql-selected {
          @apply text-primary-500;
        }

        &.ql-selected {
          font-weight: variables.$font-weight-bold;
        }
      }

      &.ql-size {
        .ql-picker-label,
        .ql-picker-item {
          &[data-value] {
            &::before {
              content: attr(data-value);
            }
          }
        }
      }

      &.ql-placeholder {
        width: 100px;

        > span {
          &.ql-picker-label::after {
            content: 'Platzhalter';
            display: inline-block;
          }

          &.ql-picker-options {
            width: 300px;

            > span {
              &.ql-picker-item::before {
                content: attr(data-label);
              }
            }
          }
        }
      }
    }
  }
}

quill-editor,
.quill-detail {
  .ql-placeholder-content {
    @apply whitespace-nowrap rounded border border-primary-500 bg-primary-50 px-1 py-0.5;

    &::before {
      content: attr(data-label);
    }

    > span {
      @apply hidden;
    }
  }
}

.quill-detail {
  .ql-align-center {
    @apply text-center;
  }

  .ql-align-right {
    @apply text-right;
  }

  .ql-align-justify {
    @apply text-justify;
  }

  p,
  ol,
  ul {
    @apply my-4;

    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  ul,
  ol {
    @apply ml-2 pl-4;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}
