@use '../base/variables';

mat-progress-bar {
  &.mat-progress-bar {
    &.upload-progress-bar {
      width: variables.$progress-bar-width;

      &.used-in-widget {
        width: variables.$widget-progress-bar-width;
      }
    }
  }
}
