// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './colors';

@import 'freshworks';
@import 'typography';
@import 'ng-select';
@import 'quill';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  @each $name, $value in colors.$mf-colors-red {
    @if $name != 'contrast' {
      // stylelint-disable-next-line custom-property-pattern
      --error-#{$name}: #{$value};
    }
  }
  @each $name, $value in colors.$mf-colors-primary {
    @if $name != 'contrast' {
      // stylelint-disable-next-line custom-property-pattern
      --primary-#{$name}: #{$value};
    }
  }
  @each $name, $value in colors.$mf-colors-monochrome {
    // stylelint-disable-next-line custom-property-pattern
    --monochrome-#{$name}: #{$value};
  }
}

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($mf-typography);`
@include mat.all-legacy-component-typographies($mf-typography);
@include mat.legacy-core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette(colors.$mf-colors-primary);
/* stylelint-disable-next-line value-keyword-case */
$frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(colors.$mf-colors-red);

// Create the theme object (a Sass map containing all of the palettes).
$frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $frontend-primary,
      accent: $frontend-accent,
      warn: $frontend-warn,
    ),
  )
);

/* Include theme styles for core and each component used in your app.
   Alternatively, you can import and @include the theme mixins for each component
   that you are using. */
@include mat.all-legacy-component-themes($frontend-theme);
@include ng-select-theme($frontend-theme);

// NOTE: This has to be imported after angular material as otherwise
// the overrides of the material styles will not work

// stylelint-disable-next-line no-invalid-position-at-import-rule
@import 'typography-fixes';
