@use '../base/variables';

.mat-list {
  padding: 0;

  &.fieldsets-navigation {
    .mat-list-item {
      @apply text-monochrome-900;

      .mat-list-item-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.separated-list {
    .mat-list-item:not(:last-child) {
      @apply border-b border-monochrome-500;

      margin-bottom: 12px;
      padding-bottom: 12px;
    }
  }

  &.auto-height {
    .mat-list-item {
      height: auto;
      min-height: 48px;
    }
  }

  &.no-side-space {
    .mat-list-item {
      .mat-list-item-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.checklist {
    margin-bottom: 24px;

    .mat-list-item {
      &.success {
        @apply text-green-500;
      }

      &.warning {
        @apply text-warn-500;
      }

      &.error {
        @apply text-error-500;
      }

      .mat-list-item-content {
        padding: 0;
      }
    }
  }

  .mat-list-item {
    @apply text-monochrome-800;

    font-size: variables.$font-size-base;
    line-height: 24px;
  }
}
