@use '../base/variables';
@use 'sass:map';

mf-layout-header {
  @apply overflow-hidden;

  > .mat-toolbar {
    @apply bg-white;

    height: variables.$header-height;
    padding-left: 50px;
    padding-right: 50px;
    user-select: none;

    &.no-line {
      @apply border-0 #{!important};
    }

    @media (min-width: map.get(variables.$breakpoints, 'xl')) {
      &:not(.sticky-header) {
        margin-bottom: -38px;
      }

      &.sticky-header {
        display: none !important;
      }
    }

    mf-layout-header-content {
      width: 100%;

      .breadcrumbs {
        opacity: 0;
        pointer-events: none;
        transition:
          opacity 0.25s ease-out,
          height 0.15s ease;
      }
    }
  }
}

.sticky-header {
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition:
    opacity 0.25s ease-out,
    height 0s ease 0.25s;
  width: 100% !important; // Fix zoom bug
  z-index: 1000;

  .breadcrumbs {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  &:not(.is-sticky) {
    height: 0 !important;
  }

  &.is-sticky {
    opacity: 1;
    transition:
      opacity 0.25s ease-out,
      height 0s ease 0s;

    .breadcrumbs {
      opacity: 1;
    }
  }
}
