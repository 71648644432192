@use '../base/variables';

.mat-select-panel-wrap {
  .mat-select-panel {
    border-radius: 0 !important;
    margin-top: 26px;

    &.multiple {
      margin-left: 0.5em !important;
    }

    > .mat-option {
      height: auto;

      &.option-small {
        font-size: variables.$font-size-small;
        height: auto;
        letter-spacing: 0.13px;
      }

      > .mat-option-text {
        line-height: 1.5em;
        padding: 0.5em 0;

        .sublabel {
          font-size: variables.$font-size-small;
        }
      }

      &.mat-selected,
      &.mat-active {
        &:not(.mat-option-multiple) {
          @apply text-primary-500;
        }
      }

      &:not(.mat-selected) {
        &:not(.mat-option-disabled) {
          .sublabel {
            @apply text-monochrome-800;
          }
        }
      }
    }
  }
}

// table filter input select
mat-form-field {
  &.mat-form-field-appearance-standard {
    &.is-table-filter {
      font-size: variables.$font-size-small;
      min-width: 60px;
      width: auto;

      &:not(.mat-focused),
      &.mat-focused {
        .mat-form-field-wrapper {
          .mat-form-field-infix {
            width: auto;

            .mat-select:not(.mat-select-empty) {
              .mat-select-value {
                max-width: variables.$input-filter-max-width;
                transition: max-width 0.2s ease-out;
                width: auto;

                .mat-select-value-text {
                  @apply text-primary-500;

                  font-weight: variables.$font-weight-medium;
                }
              }
            }
          }

          .mat-form-field-underline,
          .mat-form-field-underline > .mat-form-field-ripple {
            background-color: transparent;
          }
        }
      }
    }
  }
}
