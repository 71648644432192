@font-face {
  font-family: 'Frutiger VR';
  src:
    local('Frutiger VR Roman'),
    local('FrutigerVR-Roman'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-Regular.woff2') format('woff2'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger VR';
  src:
    local('Frutiger VR Italic'),
    local('FrutigerVR-Italic'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-Italic.woff2') format('woff2'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger VR';
  src:
    local('Frutiger VR Bold'),
    local('FrutigerVR-Bold'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-Bold.woff2') format('woff2'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger VR';
  src:
    local('Frutiger VR Bold Italic'),
    local('FrutigerVR-BoldItalic'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/frutiger-vr/FrutigerVR-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
