@use '../base/variables';

.mat-paginator {
  font-size: variables.$font-size-smaller;

  .mat-paginator-page-size {
    align-items: center;
    margin-right: 0;
  }

  &,
  .mat-select-value-text {
    @apply text-monochrome-700;
  }

  .mat-select-arrow,
  .mat-icon-button {
    @apply text-monochrome-700;
  }
}
