@use '../base/variables';

.mat-expansion-panel {
  @apply border border-monochrome-500;

  box-shadow: none !important;

  &.overflow-visible {
    &.mat-expanded {
      &,
      .mat-expansion-panel-content:not(.ng-animating) {
        overflow: visible !important;
      }
    }
  }

  &.no-content-offset {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding-bottom: 0;
      }
    }
  }

  .mat-expansion-panel-header-description,
  .mat-expansion-panel-header-title {
    align-items: center;
    font-weight: variables.$font-weight-medium;
  }

  .mat-expansion-panel-header-title {
    @apply text-monochrome-800;

    font-size: variables.$font-size-small;
  }

  .mat-expansion-panel-header {
    &.disable-header-toggle {
      pointer-events: none;

      .header-toggle-action {
        pointer-events: auto;
      }
    }

    &.expansion-panel-subheader {
      .mat-expansion-indicator {
        display: none;
      }

      .mat-content {
        justify-content: center;
      }
    }
  }

  .mat-expansion-panel-content {
    font-size: variables.$font-size-base;
    font-weight: variables.$font-weight-regular;
    overflow: hidden;

    .mat-expansion-panel-body {
      &::before {
        @apply border-t border-monochrome-600;

        content: '';
        display: block;
      }
    }
  }

  .mat-expansion-indicator {
    display: flex;
  }

  mf-info-hover {
    margin-left: 2px;
    margin-top: 3px;
    position: relative;
  }
}
