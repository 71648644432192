@use '../base/variables';

.link {
  @apply text-primary-500 transition-colors;

  text-decoration: underline;
  cursor: pointer;
  transition: color ease 200ms;

  &:hover,
  &:focus {
    @apply text-primary-700;
  }

  &.link-tertiary {
    font-weight: variables.$font-weight-medium;
    text-transform: uppercase;
  }
}
