@use '../base/variables';

.mat-autocomplete-panel {
  > .mat-optgroup {
    > .mat-optgroup-label {
      @apply text-primary-300;

      font-size: variables.$font-size-smaller;
      font-weight: variables.$font-weight-medium;
      height: calc(1.17rem + 1rem + 0.2rem);
      line-height: 1.17rem;
      padding: 1rem 11px 0.2rem;
    }

    > .mat-option {
      height: 32px;
      line-height: 32px;

      &:not(.mat-option-multiple) {
        padding: 0 11px;
      }

      &:not(.mat-option-disabled) {
        &:hover,
        &:focus {
          @apply bg-primary-50;
        }
      }

      > .mat-option-text {
        font-size: variables.$font-size-base;
        font-weight: variables.$font-weight-regular;
      }
    }
  }

  &.focus-topic-selector {
    width: inherit;

    @each $topic in variables.$topic-list {
      &.#{ $topic } {
        > .mat-optgroup > .mat-optgroup-label {
          @apply text-topic-#{ $topic }-500;
        }
      }
    }
  }
}
