@use '@angular/material' as mat;
@use 'sass:map';
@use './colors';
@use '../base/variables';

@mixin ng-select-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $isdark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $highlight-color: if(
    $isdark,
    mat.get-color-from-palette($foreground, text),
    mat.get-color-from-palette($primary)
  );

  .ng-select,
  .ng-select-container,
  .ng-input > input {
    color: mat.get-color-from-palette($foreground, text) !important;
    font: inherit;
  }

  .ng-clear-wrapper,
  .ng-arrow-wrapper {
    color: mat.get-color-from-palette($foreground, text, 0.4);
    height: 1em;
    margin-left: 8px;

    &:hover {
      color: mat.get-color-from-palette($foreground, text);
    }
  }

  .ng-select {
    .ng-select-container {
      .ng-placeholder {
        @apply text-monochrome-700;
      }

      &.ng-has-value {
        .ng-placeholder {
          display: none;
        }
      }

      .ng-value-container {
        .ng-input {
          align-items: center;
          display: flex;

          > input {
            box-sizing: border-box !important;
            height: 1.125em;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        height: 7px !important;
      }
    }

    .ng-dropdown-panel {
      width: calc(100% + 2em) !important;
    }

    &.ng-select-single {
      .ng-select-container {
        position: relative;
        top: 0;

        .ng-value-container {
          height: 1.125em;
          position: relative;
          margin-block: 0.0625em;

          .ng-value {
            .ng-value-label {
              line-height: 18px;
            }
          }
        }
      }
    }

    &.ng-select-multiple {
      .ng-value-container {
        margin-bottom: -2px;
        margin-top: -2px;

        .ng-value {
          @apply bg-monochrome-500 text-monochrome-900;

          align-items: center;
          border-radius: 10px;
          display: flex;
          flex-direction: row-reverse;
          margin: 2px 4px 2px 0;
          min-height: 24px;
          padding: 5px 8px;

          .ng-value-icon {
            @apply bg-monochrome-800 text-monochrome-500 hover:bg-monochrome-900;

            display: block !important;
            border-radius: 7px;
            flex: 0 0 14px;
            height: 14px;
            line-height: 14px;
            padding: 0 0 1px;
            text-align: center;
            vertical-align: middle;
            width: 14px;
          }

          .ng-value-label {
            font-size: variables.$font-size-smaller;
            margin-right: 4px;
            white-space: normal;
          }
        }
      }

      @each $topic in variables.$topic-list {
        &.#{ $topic } {
          .ng-value {
            @apply bg-topic-#{ $topic }-100 text-topic-#{ $topic }-700;

            .ng-value-icon {
              @apply bg-topic-#{ $topic }-700 text-topic-#{ $topic }-100;

              &:hover,
              &:focus {
                @apply bg-topic-#{ $topic }-800;
              }
            }
          }

          .ng-dropdown-panel {
            .ng-dropdown-panel-items {
              .ng-optgroup {
                @apply text-topic-#{ $topic }-500;
              }

              .ng-option {
                &.ng-option-marked,
                &.ng-option-selected {
                  @apply text-topic-#{ $topic }-500;
                }
              }
            }
          }
        }
      }
    }

    .ng-spinner-loader {
      border-color: mat.get-color-from-palette(colors.$mf-colors-primary, 100) !important;
      border-bottom-color: mat.get-color-from-palette(colors.$mf-colors-primary, 500) !important;
    }

    &.no-virtual-scroll {
      .ng-option {
        height: auto !important;
        min-height: 32px;
        text-overflow: unset !important;
        white-space: normal !important;

        @apply leading-normal #{!important};
      }
    }

    &:not(.no-virtual-scroll) {
      &.has-sublabels {
        .ng-option {
          @apply h-[3.1rem];
        }
      }
    }

    &.ng-select-disabled {
      .ng-select-container {
        .ng-value {
          @apply bg-monochrome-200 text-monochrome-700;
        }
      }
    }
  }

  .ng-dropdown-panel {
    @apply border border-monochrome-100 shadow-lg;

    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text) !important;
    left: 5px;
    margin-left: -1em !important;

    .mat-option {
      &.ng-option-selected {
        &:not(.ng-option-marked) {
          &:not(:hover) {
            background: mat.get-color-from-palette($background, card);

            &:not(.ng-option-disabled) {
              color: mat.get-color-from-palette($foreground, text);
            }
          }
        }
      }
    }

    // left: 0;
    &.ng-select-bottom {
      top: calc(100% + 0.5em);
    }

    &.ng-select-top {
      bottom: calc(100% + 1.25em);
    }

    &.multiple {
      .ng-option {
        &.selected {
          background: mat.get-color-from-palette($background, card);
        }

        &.marked {
          background: mat.get-color-from-palette($foreground, text, 0.04);
        }
      }
    }

    .ng-dropdown-header {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, text, 0.12);
      font-size: variables.$font-size-small;

      .add-all-items-buttom {
        background: transparent;
        border: 0;
        cursor: pointer;
        height: 100%;
        padding: 12px 16px;
        text-align: left;
        width: 100%;

        &:hover {
          background: rgb(245 245 245 / 60%);
        }
      }
    }

    .ng-dropdown-footer {
      border-top: 1px solid mat.get-color-from-palette($foreground, text, 0.12);
      line-height: 3em;
      min-height: 3em;
      padding: 0 16px;
    }

    .ng-dropdown-panel-items {
      max-height: 330px !important;

      .ng-option,
      .ng-optgroup {
        @apply px-4 text-sm leading-none;
      }

      .ng-optgroup {
        @apply cursor-default py-2 font-bold text-primary-500;

        height: 32px;
        min-height: 32px;
        user-select: none;

        &:not(:first-child) {
          @apply border-t border-monochrome-100;
        }

        &.ng-option-marked {
          background: rgb(245 245 245 / 60%);
        }

        &.ng-option-selected {
          background: rgb(245 245 245 / 60%);
          color: $highlight-color;
        }
      }

      .ng-option {
        @apply h-8 py-2.5 text-monochrome-900 transition-colors;

        overflow: hidden;
        position: relative;
        text-align: left;
        text-decoration: none;

        // Option is hovered
        &.ng-option-marked {
          @apply bg-monochrome-100;
        }

        // Option is currently selected
        &.ng-option-selected {
          @apply bg-primary-50 font-medium text-primary-500;

          .sublabel {
            @apply font-normal text-primary-400;
          }
        }

        &.ng-option-disabled {
          @apply cursor-default text-monochrome-300;
        }

        .ng-tag-label {
          color: mat.get-color-from-palette($foreground, text, 0.38);
          font-size: 80%;
          font-weight: 400;
          padding-right: 5px;
        }

        .sublabel {
          @apply block text-sm text-monochrome-800;
        }
      }
    }
  }
}
