@use '../base/variables';

mf-data-manager-widget {
  bottom: variables.$page-container-padding-y;
  position: fixed;
  right: variables.$page-container-padding-x;
  z-index: 990;

  .has-success {
    .mat-icon {
      @apply text-green-500;
    }
  }
}
