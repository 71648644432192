@use '../utils';
@use '../base/variables';

.no-content-hint {
  @include utils.size(100%, auto);

  @apply border-b border-t border-monochrome-600 text-monochrome-800;

  font-size: variables.$font-size-small;
  padding: 32px 8px;
}
