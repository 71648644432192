/**
 * reading resources to colors
 *   - https://material.angular.io/guide/theming
 *   - https://material.angular.io/guide/customizing-component-styles
 *
 * if we need success color in app, read this https://github.com/angular/components/issues/11888#issuecomment-536509515
 */
@forward 'colors/monochrome';
@forward 'colors/primary';
@forward 'colors/error';
