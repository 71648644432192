@font-face {
  font-family: 'GenosGFG';
  src:
    local('GenosGFG Regular'),
    local('GenosGFG-Regular'),
    url('/assets/fonts/genos-gfg/GenosGFG-Regular.woff2') format('woff2'),
    url('/assets/fonts/genos-gfg/GenosGFG-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GenosGFG';
  src:
    local('GenosGFG Regular Italic'),
    local('GenosGFG-RegularItalic'),
    url('/assets/fonts/genos-gfg/GenosGFG-RegularItalic.woff2') format('woff2'),
    url('/assets/fonts/genos-gfg/GenosGFG-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GenosGFG Condensed';
  src:
    local('GenosGFG Condensed'),
    local('GenosGFG-Condensed'),
    url('/assets/fonts/genos-gfg/GenosGFG-Condensed.woff2') format('woff2'),
    url('/assets/fonts/genos-gfg/GenosGFG-Condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GenosGFG';
  src:
    local('GenosGFG Bold'),
    local('GenosGFG-Bold'),
    url('/assets/fonts/genos-gfg/GenosGFG-Bold.woff2') format('woff2'),
    url('/assets/fonts/genos-gfg/GenosGFG-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GenosGFG';
  src:
    local('GenosGFG Bold Italic'),
    local('GenosGFG-BoldItalic'),
    url('/assets/fonts/genos-gfg/GenosGFG-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/genos-gfg/GenosGFG-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
