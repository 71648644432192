@use '../base/variables';

.mat-stepper-vertical {
  .mat-step-header {
    .mat-step-icon {
      @apply h-8 w-8 bg-monochrome-800 text-white;

      &.mat-step-icon-selected {
        @apply bg-primary-500;
      }
    }

    .mat-step-label {
      @extend .mat-headline;

      margin: 0;

      &:not(.mat-step-label-active) {
        @apply text-monochrome-900;
      }

      &.mat-step-label-selected {
        font-weight: variables.$font-weight-regular;
      }
    }
  }

  .mat-vertical-content-container {
    margin-left: 40px;
  }

  .mat-stepper-vertical-line::before {
    @apply border-l-2 border-monochrome-100;
  }
}
