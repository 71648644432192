@use '../base/variables';

.mat-display-2 {
  @apply text-primary-500;

  margin-bottom: 40px;
}

.mat-display-1 {
  @apply text-monochrome-700;
}

.mat-headline,
.mat-h1 {
  @apply text-monochrome-900;
}

.mat-title,
.mat-h2 {
  @apply text-primary-500;
}

.mat-subheading-1,
.mat-subheading-2,
.mat-h3 {
  @apply text-monochrome-700;

  margin-top: 24px;
}

.mat-body-1,
.mat-body {
  @apply text-monochrome-800;
}

.mat-small,
.mat-caption {
  @apply text-monochrome-800;
}

.mat-body-2 {
  @apply text-monochrome-900;
}

p {
  @apply text-monochrome-800;

  font-size: variables.$font-size-base;
  line-height: variables.$font-size-base * 1.5;
}

input {
  @apply text-monochrome-900;
}
