/* stylelint-disable scss/dollar-variable-empty-line-before */
$breakpoints: (
  '': '',
  'sm': 600px,
  'md': 855px,
  'lg': 1155px,
  'xl': 1435px,
);

$header-height: 72px;
$footer-height: 48px;

$sidenav-width: 280px;

$font-size-3xl: 26px;
$font-size-2xl: 24px;
$font-size-xl: 22px;
$font-size-larger: 20px;
$font-size-large: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-smaller: 12px;
$font-sizes: (
  3xl: $font-size-3xl,
  2xl: $font-size-2xl,
  xl: $font-size-xl,
  larger: $font-size-larger,
  large: $font-size-large,
  base: $font-size-base,
  small: $font-size-small,
  smaller: $font-size-smaller,
);

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$page-container-padding-x: 50px;
$page-container-padding-y: 64px;

$input-height: 44px;
$input-filter-max-width: 200px;

$form-container-width: (
  full: 100%,
  xxl: 760px,
  xl: 620px,
  lg: 480px,
  md: 360px,
  sm: 300px,
  xs: 145px,
);

$border-radius: 0;

$card-border-radius: 4px;

$table-row-height: 54px;

$dropdown-item-height: 54px;
$dropzone-height: 102px;

$progress-bar-width: 500px;

$container-max-width: 1320px;

$widget-progress-bar-width: 260px;
$widget-width: 320px;
$widget-min-height: 72px;

$topic-list: liquidity, hedge, asset, provision, additional, real_estate;
