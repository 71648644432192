@use '@angular/material' as mat;
@use '../framework/colors';
@use '../base/variables';

@keyframes load {
  from {
    top: -200px;
  }

  to {
    top: 100%;
  }
}

.finance-house {
  @apply text-monochrome-800;

  font-size: variables.$font-size-base;
  line-height: 1.6;

  .container {
    @apply border border-monochrome-500;

    align-items: center;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    position: relative;
  }

  .loader {
    @apply bg-white;

    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition:
      opacity 0.3s ease,
      visibility 0s 0.3s;
    visibility: visible;
    width: 100%;
    z-index: 10;

    &.hidden {
      opacity: 0;
      visibility: hidden;

      &::before {
        animation: none;
      }
    }

    &::before {
      animation: load 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      background: linear-gradient(
        transparent 0%,
        mat.get-color-from-palette(colors.$mf-colors-monochrome, 100) 50%,
        transparent 100%
      );
      content: '';
      display: block;
      height: 200px;
      left: 0;
      position: absolute;
      top: -200px;
      width: 100%;
    }
  }

  .roof {
    @apply text-monochrome-500;
  }
}
