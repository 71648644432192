@use '/src/stylesheets/base/variables';

@each $topic in variables.$topic-list {
  .#{ $topic } {
    &.colored,
    .colored {
      @apply text-topic-#{ $topic }-500 #{!important};
    }

    &.border-colored,
    .border-colored {
      @apply border-topic-#{ $topic }-500 #{!important};
    }

    &.bg-colored,
    .bg-colored {
      @apply bg-topic-#{ $topic }-500 #{!important};
    }

    &.bg-colored-light,
    .bg-colored-light {
      @apply bg-topic-#{ $topic }-100 #{!important};
    }
  }

  .all,
  .additional {
    &.focus-topic-high-contrast {
      &.colored,
      .colored {
        color: rgb(0 0 0 / 85%) #{!important};
      }

      &.border-colored,
      .border-colored {
        border-color: rgb(0 0 0 / 85%) #{!important};
      }

      &.bg-colored,
      .bg-colored {
        border-color: rgb(0 0 0 / 85%) #{!important};
      }
    }
  }
}
