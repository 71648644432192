.mat-tab-header {
  border-bottom: 0 !important;

  .mat-tab-label {
    .mat-tab-label-content {
      @apply uppercase;
    }

    .mat-title {
      @apply my-4;
    }
  }

  .mat-tab-label-active {
    opacity: 1;
  }
}

.mat-tab-body {
  .mat-tab-body-content {
    overflow: hidden;
    padding-top: 32px;

    &.ng-animating {
      overflow-y: hidden;
    }
  }
}
