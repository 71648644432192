.cursor-inline-edit:not(:focus, :disabled) {
  cursor:
    url('/assets/images/mouse-cursor/edit.svg') 0 24,
    url('/assets/images/mouse-cursor/edit.png') 0 24,
    auto;
}

.simulate-inline-edit {
  @apply -mx-1 rounded-sm px-1 py-1 leading-none outline-monochrome-700 hover:outline hover:outline-1 #{!important};
}
